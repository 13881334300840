<template>
  <div
    v-if="checkDesktop"
    class="registerForm-section"
  >
    <BreadCrumb
      v-if="step === 1"
      class="top-left"
      :is-use-route-name="true"
    />
    <!-- Form -->
    <div
      v-if="step === 1"
      class="registerForm-section__form"
    >
      <div class="registerForm-section__form__coat">
        <h1 class="registerForm-section__form__coat__title">
          Daftar Akun Lion Parcel
        </h1>
        <div class="registerForm-section__form__coat__wrapper">
          <Input
            v-model="register.name"
            name="name"
            placeholder="Masukan nama pendaftar"
            :is-error="nameError"
            :error-message="nameErrorMsg"
            label="Nama Pendaftar"
            :is-required="true"
            @input="handleInput('name')"
          />
        </div>
        <div class="registerForm-section__form__coat__wrapper">
          <Dropdown
            v-model="register.city"
            :options="register.cityList"
            :value="register.city"
            name="city"
            placeholder="Pilih kota domisili"
            :is-error="cityError"
            :error-message="cityErrorMsg"
            label="Kota Domisili"
            :is-required="true"
            :is-search="true"
            @input="handleInput('city')"
          />
        </div>
        <div class="registerForm-section__form__coat__wrapper">
          <Input
            v-model="register.handphone"
            name="handphone"
            prefix="+62"
            placeholder="diawali dengan angka 8"
            :is-error="handphoneError"
            :error-message="handphoneErrorMsg"
            label="No. Handphone"
            :is-required="true"
            note="Pastikan no. kamu aktif dan bisa menerima OTP"
            @input="handleInput('handphone')"
          />
        </div>
        <div class="registerForm-section__form__coat__wrapper">
          <Input
            v-model="register.email"
            name="email"
            placeholder="Masukan email pendaftar"
            :is-error="emailError"
            :error-message="emailErrorMsg"
            label="Email"
            :is-required="true"
            @input="handleInput('email')"
          />
        </div>
        <div class="registerForm-section__form__coat__wrapper">
          <Input
            v-model="register.password"
            name="password"
            placeholder="Masukan Password"
            :is-error="passwordError"
            :error-message="passwordErrorMsg"
            :is-password="true"
            label="Password"
            :is-required="true"
            @input="handleInput('password')"
          />
        </div>
        <div class="registerForm-section__form__coat__wrapper">
          <Input
            v-model="register.confirmPassword"
            name="confirmPassword"
            placeholder="Konfirmasi Password"
            :is-error="confirmPasswordError"
            :error-message="confirmPasswordErrorMsg"
            :is-password="true"
            label="Konfirmasi Password"
            :is-required="true"
            @input="handleInput('confirmPassword')"
          />
        </div>
        <div class="registerForm-section__form__coat__wrapper">
          <Input
            v-model="register.referralCode"
            name="refferalCode"
            placeholder="Masukan kode referral"
            label="Kode Referral"
            :is-optional="true"
          />
        </div>
        <div class="registerForm-section__form__coat__wrapper">
          <div class="registerForm-section__form__coat__wrapper__cover">
            <Button
              id="Registration_User"
              text="Daftar"
              class="registerForm-section__form__coat__wrapper__cover__button"
              @click="sendOTP"
            />
          </div>
        </div>
        <div class="registerForm-section__form__coat__wrapper">
          <div class="registerForm-section__form__coat__wrapper__note">
            Sudah punya akun Lion Parcel?<span @click="Login">Masuk</span>
          </div>
        </div>
      </div>
    </div>
    <!-- Form -->
    <!-- OTP Form -->
    <div v-if="step === 2">
      <OTP
        :data="register"
        :step="step"
        :error-otp="OTPError"
        :error-message="OTPErrorMsg"
        @change-data="goBack"
        @send-otp="sendOTP"
        @check-otp="checkOTP"
        @remove-error="removeErrorOTP"
      />
    </div>
    <!-- OTP Form -->
    <!-- Result Success-->
    <div v-if="step === 3">
      <Success :data="register.handphone" />
    </div>
    <!-- Result Success-->
  </div>
</template>

<script>
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb';
import mixinMobile from '@/misc/mixinMobile';
import mixinMetaInfo from '@/misc/mixinMetaInfo';
import Input from './components/Input.vue';
import Dropdown from './components/Dropdown.vue';
import Button from '@/components/new-button/Button.vue';
import Location from './app/usecase/location';
import Register from './app/usecase/register';
import OTP from './otp/Otp.vue';
import Success from './otp/Success.vue';

export default {
  name: 'RegisterForm',
  components: {
    BreadCrumb, Input, Dropdown, Button, OTP, Success,
  },
  mixins: [mixinMetaInfo, mixinMobile],
  data() {
    return {
      searchMetaInfo: '_registerForm', // from mixinMetaInfo, path tapi / dengan _ ex: /about jadi _about
      step: 1,
      OTPError: false,
      OTPErrorMsg: '',
      register: {
        name: '',
        cityList: [],
        city: [],
        cityVal: '',
        handphone: '',
        email: '',
        password: '',
        confirmPassword: '',
        referralCode: '',
        os: 'WEBSITE',
      },
      nameError: false,
      nameErrorMsg: '',
      cityError: false,
      cityErrorMsg: '',
      handphoneError: false,
      handphoneErrorMsg: '',
      emailError: false,
      emailErrorMsg: '',
      passwordError: false,
      passwordErrorMsg: '',
      confirmPasswordError: false,
      confirmPasswordErrorMsg: '',
    };
  },
  computed: {
    checkDesktop() {
      return this.windowWidth > 599;
    },
  },
  mounted() {
    this.setLocation();
    if (!this.checkDesktop) {
      this.$router.push('/');
    }
    window.scrollTo(0, 0);
  },
  methods: {
    removeErrorOTP() {
      this.OTPError = false;
    },
    async setLocation() {
      try {
        const res = await Location.getLocation();
        if (res.data) {
          this.register.cityList = res.data.map(loc => ({
            label: loc.city,
            value: loc.route_algo_id,
          }));
        }
      } catch (error) {
        return error;
      }
    },
    Login() {
      window.open(process.env.VUE_APP_AKUN_DOMAIN_URL);
    },
    handleInput(name) {
      switch (name) {
        case 'name':
          this.nameError = false;
          break;
        case 'city':
          this.cityError = false;
          break;
        case 'handphone':
          this.handphoneError = false;
          break;
        case 'email':
          this.emailError = false;
          break;
        case 'password':
          this.passwordError = false;
          break;
        case 'confirmPassword':
          this.confirmPasswordError = false;
          break;
        default: break;
      }
    },
    _phoneElseCondition() {
      if (this.register.handphone.charAt(0) !== '8') {
        this.handphoneError = true;
        this.handphoneErrorMsg = 'Format yang anda masukan salah';
      } else if (this.register.handphone.length < 9 || this.register.handphone.length > 12) {
        // inc prefix +62 (total min 12 char - max 15 char)
        this.handphoneError = true;
        this.handphoneErrorMsg = 'Panjang karakter antara 9-12 angka';
      }
    },
    check() {
      // name
      if (!this.register.name) {
        this.nameError = true;
        this.nameErrorMsg = 'Nama Pendaftar harus diisi';
      }
      // city
      try {
        this.register.cityVal = this.register.city[0].value;
      } catch (err) {
        this.register.cityVal = '';
      }
      if (!this.register.cityVal) {
        this.cityError = true;
        this.cityErrorMsg = 'Kota Domisili harus dipilih';
      }
      // handphone
      if (!this.register.handphone) {
        this.handphoneError = true;
        this.handphoneErrorMsg = 'No. Handphone harus diisi';
      } else {
        this._phoneElseCondition();
      }
      // email
      if (!this.register.email) {
        this.emailError = true;
        this.emailErrorMsg = 'Email harus diisi';
      } else if (!(/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(this.register.email))) {
        this.emailError = true;
        this.emailErrorMsg = 'Format yang anda masukan salah';
      }
      // password
      if (!this.register.password) {
        this.passwordError = true;
        this.passwordErrorMsg = 'Password harus diisi';
      } else if (this.register.password.length < 8 || this.register.password.length > 72) {
        this.passwordError = true;
        this.passwordErrorMsg = 'Password harus 8 hingga 72 karakter';
      }
      // confirmPassword
      if (!this.register.confirmPassword) {
        this.confirmPasswordError = true;
        this.confirmPasswordErrorMsg = 'Password harus diisi';
      } else if (this.register.confirmPassword !== this.register.password) {
        this.confirmPasswordError = true;
        this.confirmPasswordErrorMsg = 'Password yang kamu masukan tidak sama';
      } else this.confirmPasswordError = false;

      return !this.nameError
      && !this.cityError
      && !this.handphoneError
      && !this.emailError
      && !this.passwordError
      && !this.confirmPasswordError;
    },
    goBack() {
      this.step = 1;
    },
    async sendOTP() {
      if (this.check()) {
        const otpRequestPayload = {
          phone_number: `+62${this.register.handphone}`,
          role: 'CUSTOMER',
          otp_type: 'REGISTER',
        };
        const res = await Register.requestOTP(otpRequestPayload);
        if (res.data) {
          this.otpId = res.data.otp_id;
          this.step = 2;
          window.scrollTo(0, 0);
        }
        if (res.err) {
          alert(res.err.id);
        }
      }
    },
    async checkOTP(otp) {
      const otpExchangePayload = {
        otp_id: this.otpId,
        otp,
      };
      const res = await Register.exchangeOTP(otpExchangePayload);
      if (res.data) {
        this.doRegister(res.data.token);
      }
      if (res.err) {
        this.OTPError = true;
        this.OTPErrorMsg = 'Kode verifikasi tidak benar, ulangi kembali';
      }
    },
    async doRegister(token) {
      const register = {
        token,
        fullname: this.register.name,
        city: this.register.city[0].value,
        phone_number: `+62${this.register.handphone}`,
        email: this.register.email,
        password: this.register.password,
        password_confirm: this.register.confirmPassword,
        referral_code: this.register.referralCode,
        os: this.register.os,
      };
      const res = await Register.registerAccount(register);
      if (res.data) {
        this.step = 3;
        window.scrollTo(0, 0);
      }
      if (res.err) {
        alert(res.err.id);
      }
    },
  },
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
