import InternalAPI from '@/app/baseAxios';

/** @borrows InternalAPI */
export default class Location extends InternalAPI {
  constructor() {
    super();
    /** @type {string} */
    this.location_endpoint = '/v1/route-algo';
  }

  /**
     *
     * @return {Promise<{data: Locations, err: ErrorResponse}>}
    */
  async getLocation() {
    try {
      const res = await this.$axios.get(`${this.location_endpoint}?page=1&per_page=300&status=true`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: res.data, err: null };
    } catch (err) {
      return { data: null, err: err.response.data.message };
    }
  }
}
