import CustomerAppAPI from '@/app/customerAppAxios';
import '../types';

/** @borrows CustomerAppAPI */
export default class Register extends CustomerAppAPI {
  constructor() {
    super();
    /** @type {string} */
    this.otpRequest_endpoint = 'v1/account/auth/otp/request';
    this.otpExchange_endpoint = 'v1/account/auth/otp/exchange';
    this.register_endpoint = '/v3/account/auth/customer/register';
  }

  /**
     *
     * @param payload {Object}
     * @return {Promise<{data: RequestOtpResponse, err: ErrorResponse}>}
    */
  async requestOTP(payload) {
    try {
      const res = await this.$axios.post(this.otpRequest_endpoint, payload, {
        headers: {
          Authorization: process.env.VUE_APP_TOKEN_URL,
          'Content-Type': 'application/json',
        },
      });
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: res.data, err: null };
    } catch (err) {
      return { data: null, err: err.response.data.message };
    }
  }

  /**
     *
     * @param payload {Object}
     * @return {Promise<{data: ExchangeOtpResponse, err: ErrorResponse}>}
    */
  async exchangeOTP(payload) {
    try {
      const res = await this.$axios.post(this.otpExchange_endpoint, payload, {
        headers: {
          Authorization: process.env.VUE_APP_TOKEN_URL,
          'Content-Type': 'application/json',
        },
      });
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: res.data, err: null };
    } catch (err) {
      return { data: null, err: err.response.data.message };
    }
  }

  /**
     *
     * @param payload {Object}
     * @return {Promise<{data: RegisterResponse, err: ErrorResponse}>}
    */
  async registerAccount(payload) {
    try {
      const res = await this.$axios.post(this.register_endpoint, payload, {
        headers: {
          Authorization: process.env.VUE_APP_TOKEN_URL,
          'Content-Type': 'application/json',
        },
      });
      if (res.status === 200) {
        window.gtag('event', 'Registration_Success', { event_category: 'Registration Success', event_label: 'Registration Success' });
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: res.data, err: null };
    } catch (err) {
      return { data: null, err: err.response.data.message };
    }
  }
}
