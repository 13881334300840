<template>
  <div class="content-otp">
    <p
      v-show="timeLeft > 0"
      class="title-verified"
    >
      Verifikasi No. Handphone Kamu
    </p>
    <div v-show="timeLeft > 0">
      <p class="text-otp">
        Masukkan kode verifikasi yang sudah kami<br> kirimkan ke nomor {{ initialPhone }}
      </p>
    </div>
    <div class="otp-wrapper">
      <div
        v-if="timeLeft > 0"
        class="otp-coat"
      >
        <input
          id="first"
          v-model="digitFirst"
          type="text"
          :class="errorOtp ? 'input-otp error' : 'input-otp'"
          maxlength="1"
          @keyup="clickEvent(digitFirst, 'second')"
          @change="$emit('remove-error')"
        >
        <input
          id="second"
          v-model="digitSecond"
          type="text"
          :class="errorOtp ? 'input-otp error' : 'input-otp'"
          maxlength="1"
          @keyup="clickEvent(digitSecond, 'third')"
          @change="$emit('remove-error')"
        >
        <input
          id="third"
          v-model="digitThird"
          type="text"
          :class="errorOtp ? 'input-otp error' : 'input-otp'"
          maxlength="1"
          @keyup="clickEvent(digitThird, 'fourth')"
          @change="$emit('remove-error')"
        >
        <input
          id="fourth"
          v-model="digitFourth"
          type="text"
          :class="errorOtp ? 'input-otp error' : 'input-otp'"
          maxlength="1"
          @keyup="clickEvent(digitFourth, 'fifth')"
          @change="$emit('remove-error')"
        >
        <input
          id="fifth"
          v-model="digitFifth"
          type="text"
          :class="errorOtp ? 'input-otp error' : 'input-otp'"
          maxlength="1"
          @keyup="clickEvent(digitFifth, 'sixth')"
          @change="$emit('remove-error')"
        >
        <input
          id="sixth"
          v-model="digitSixth"
          type="text"
          :class="errorOtp ? 'input-otp error' : 'input-otp'"
          maxlength="1"
          @input="$emit('remove-error')"
        >
      </div>
      <div 
        v-else
        class="otp-result"
      >
        <ImageLazy
          :src="'/images/failed-register.svg'"
          alt="failed"
          width="190"
          height="190"
        />
        <p class="title-desc">
          Verifikasi No. Handphone gagal
        </p>
        <p class="otp-failed">
          Waktu kamu habis, Lakukan verifikasi kembali
        </p>
      </div>
    </div>

    <p
      v-show="errorOtp && timeLeft > 0"
      class="error-otp"
    >
      {{ errorMessage }}
    </p>
    <p
      v-show="timeLeft > 0"
      class="text-time"
    >
      Masukkan kode verifikasi sebelum {{ formattedTimeLeft }}
    </p>
    <div class="button-otp-wrapper">
      <Button
        text="Ubah Data"
        type-class="outline-red"
        @click="previousStep"
      />
      <Button
        :text="timeLeft > 0 ? 'Verifikasi OTP' : 'Verifikasi Ulang'"
        :disabled="errorOtp && timeLeft > 0"
        @click="timeLeft > 0 ? checkOTP() : resendOTP()"
      />
    </div>
  </div>
</template>
<script>
import Button from '@/components/new-button/Button.vue';
import ImageLazy from "@/components/ImageLazy/index.vue";

export default {
  components: { Button, ImageLazy },
  props: {
    data: {
      type: Object,
      required: true
    },
    step: {
      type: Number,
      required: true
    },
    errorOtp: {
      type: Boolean,
      default: true
    },
    errorMessage: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      digitFirst: "",
      digitSecond: "",
      digitThird: "",
      digitFourth: "",
      digitFifth: "",
      digitSixth: "",
      timerInterval: null,
      timeLimit: 60,
      timePassed: 0,
    }
  },
  computed: {
    initialPhone() {
      return (
        "0" +
        this.data.handphone.slice(0, this.data.handphone.length - 4) +
        "**" +
        this.data.handphone.slice(
          this.data.handphone.length - 2,
          this.data.handphone.length
        )
      );
    },
    otp() {
      return (
        this.digitFirst + this.digitSecond + this.digitThird + this.digitFourth + this.digitFifth + this.digitSixth
      );
    },
    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },
    timeLeft() {
      return this.timeLimit - this.timePassed;
    },
  },
  watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimesUp();
      }
    },
  },
  mounted() {
    if (this.step === 2) {
      this.startTimer()
    }
  },
  methods: {
    previousStep() {
      this.onTimesUp();
      this.timePassed = 0;
      this.$emit('remove-error')
      this.digitFirst = "";
      this.digitSecond = "";
      this.digitThird = "";
      this.digitFourth = "";
      this.digitFifth = "";
      this.digitSixth = "";
      window.scrollTo(0, 0);
      this.$emit('change-data')
    },
    clickEvent(first, last) {
      if (first.length) {
        document.getElementById(last).focus();
      }
    },
    onTimesUp() {
      clearInterval(this.timerInterval);
    },
    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    async sendOTP() {
      this.$emit('send-otp')
    },
    resendOTP() {
      this.onTimesUp();
      this.$emit('remove-error')
      this.timePassed = 0;
      this.startTimer();
      this.digitFirst = "";
      this.digitSecond = "";
      this.digitThird = "";
      this.digitFourth = "";
      this.digitFifth = "";
      this.digitSixth = "";
      this.sendOTP();
    },
    checkOTP() {
      this.$emit('check-otp', this.otp)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
