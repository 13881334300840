import RegisterRepository from '../api/register';

class RegisterUseCase {
  constructor() {
    /** @type {RegisterRepository} */
    this.api = new RegisterRepository();
  }

  /**
     *
     * @param payload {Object}
     * @return {Promise<{data: {RequestOtpResponse}, err: ErrorResponse}>}
    */
  async requestOTP(payload) {
    const { data, err } = await this.api.requestOTP(payload);
    if (data) {
      return { data, err: null };
    }
    return { data: null, err };
  }

  /**
     *
     * @param payload {Object}
     * @return {Promise<{data: {ExhcangeOtpResponse}, err: ErrorResponse}>}
    */
  async exchangeOTP(payload) {
    const { data, err } = await this.api.exchangeOTP(payload);
    if (data) {
      return { data, err: null };
    }
    return { data: null, err };
  }

  /**
     *
     * @param payload {Object}
     * @return {Promise<{data: {RegisterResponse}, err: ErrorResponse}>}
    */
  async registerAccount(payload) {
    const { data, err } = await this.api.registerAccount(payload);
    if (data) {
      return { data, err: null };
    }
    return { data: null, err };
  }
}

export default new RegisterUseCase();
