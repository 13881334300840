<template>
  <div class="content-otp">
    <div class="otp-result">
      <ImageLazy
        :src="'/images/success-register.svg'"
        alt="success"
        width="190"
        height="190"
      />
      <p class="title-desc">
        Verifikasi No. Handphone Berhasil
      </p>
      <p class="otp-failed">
        No kamu {{ initialPhone }} terverifikasi
      </p>
    </div>
    <div class="button-otp-wrapper">
      <Button
        text="Masuk Sekarang"
        @click="Login"
      />
    </div>
  </div>
</template>
<script>
import Button from '@/components/new-button/Button.vue';
import ImageLazy from "@/components/ImageLazy/index.vue";

export default {
  components: { Button, ImageLazy },
  props: {
    data: {
      type: String,
      default: "",
      required: true
    },
  },
  data() {
    return {}
  },
  computed: {
    initialPhone() {
      return (
        "0" +
        this.data.slice(0, this.data.length - 4) +
        "**" +
        this.data.slice(
          this.data.length - 2,
          this.data.length
        )
      );
    }
  },
  methods: {
    Login() {        
        window.open(process.env.VUE_APP_AKUN_DOMAIN_URL)
        document.location.href = '/'
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
