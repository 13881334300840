import LocationRepository from '../api/location';

class LocationUseCase {
  constructor() {
    /** @type {RegisterRepository} */
    this.api = new LocationRepository();
  }

  /**
     *
     * @return {Promise<{data: Locations, err: String}>}
    */
  async getLocation() {
    const { data, err } = await this.api.getLocation();
    if (data.data) {
      return { data: data.data, err: null };
    }
    return { data: null, err };
  }
}

export default new LocationUseCase();
